import HeroCarousel from '@/components/cms/HeroCarousel';

const HeroCarouselWrapper = ({ blok }) => {
  let { slides } = blok;

  // Force only the first slide to be "eager"
  const returnSlides = slides.map((slide, index) => {
    const { hero } = slide;
    const firstHero = hero.length > 0 ? hero[0] : {};

    const isEager = index === 0 || slides.length < 1;

    const returnHero = {
      ...firstHero,
      eager: isEager
    };

    const returnSlide = {
      ...slide,
      hero: [returnHero]
    };

    return returnSlide;
  });

  return <HeroCarousel slides={returnSlides} />;
};

export default HeroCarouselWrapper;
