const carouselInteractions = () => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'carousel_interaction'
  });
};

export default carouselInteractions;
