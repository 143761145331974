import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import Theme from '@/components/cms/Theme';
import Button from '@/components/global/Button';
import { useHeaderTheme } from '@/components/providers/HeaderThemeProvider';
import carouselInteractions from '@/lib/datalayer/carouselinteractions';
import { useScreenSize } from '@/lib/hooks/mediaQuery';

import DynamicComponent from '../../../storyblok/components/DynamicComponent';
import styles from './HeroCarousel.module.scss';

interface PropI {
  slides: Array<any>;
}

const HeroCarousel: React.FC<PropI> = ({ slides }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(null);
  const carouselItems = useRef([]);
  const carouselRef = useRef<HTMLDivElement>();
  const { setHeaderTheme, headerState } = useHeaderTheme();
  const activeSlide = slides[carouselIndex];
  const screen = useScreenSize();

  useEffect(() => {
    // Update theme colours
    setHeaderTheme(activeSlide.theme);
    //  setControlsTheme(activeSlide.theme, controlRef.current);
  }, [activeSlide.theme, carouselIndex, setHeaderTheme]);

  useEffect(() => {
    scrollLeft === null ? () => {} : carouselInteractions();
  }, [scrollLeft]);

  useEffect(() => {
    // Detect Scroll events to update controls index
    const onScroll = () => {
      if (!carouselItems.current || carouselItems.current.length === 0) return;
      carouselItems.current.forEach((slide, idx) => {
        const rect = slide.getBoundingClientRect();
        if (rect.x === 0 && idx !== carouselIndex) {
          setCarouselIndex(idx);
        }
      });
    };
    const scroller = document.getElementsByClassName(styles.carouselScroller)[0];
    scroller.addEventListener('scroll', onScroll);
    // touchend event added to register swiper interactions
    scroller.addEventListener('touchend', () => {
      setTimeout(() => {
        scrollLeft !== scroller.scrollLeft && setScrollLeft(scroller.scrollLeft);
      }, 1000);
    });
    return () => scroller.removeEventListener('scroll', onScroll);
  }, [carouselIndex, scrollLeft]);

  const scrollCarouselToIndex = (toIndex) => {
    // swiper interaction registered
    carouselInteractions();
    const active = carouselItems.current[toIndex];
    carouselRef.current.scrollTo({ top: 0, left: active.offsetLeft, behavior: 'smooth' });
  };

  const scrollToNextPage = () => {
    const lastSlide = slides.length - 1;
    const newIndex = carouselIndex === lastSlide ? 0 : carouselIndex + 1;
    scrollCarouselToIndex(newIndex);
    setCarouselIndex(newIndex);
  };

  const scrollToPrevPage = () => {
    const lastSlide = slides.length - 1;
    const newIndex = carouselIndex === 0 ? lastSlide : carouselIndex - 1;
    scrollCarouselToIndex(newIndex);
    setCarouselIndex(newIndex);
  };

  return (
    <div className={styles.heroCarousel}>
      <div
        className={clsx(
          headerState.theme && `th-${headerState.theme.toLowerCase()}`,
          styles.controls,
          headerState.fixed && styles.hideControls,
          screen !== 'Mobile' && headerState.menuOpen && styles.openMenuHide
        )}
      >
        <Button title="Previous" callBack={scrollToPrevPage} variant="ghost" size="none">
          ←
        </Button>
        <h6>{activeSlide.title}</h6>
        <Button title="Next" callBack={scrollToNextPage} variant="ghost" size="none">
          →
        </Button>
      </div>
      <div ref={carouselRef} className={styles.carouselScroller}>
        {slides.map((slide, idx) => (
          <div
            id={`${idx}`}
            className={styles.slide}
            key={idx}
            ref={(el) => (carouselItems.current[idx] = el)}
          >
            <Theme variant={slide.theme} applyBackground>
              <DynamicComponent blok={slide?.hero?.[0]} />
            </Theme>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroCarousel;
